import RTLSearch from './rtl-search';
import documentsTranslationsEn from './database/translations/en.json'
import documentsTranslationsPl from './database/translations/pl.json'

const documentsTranslations = {
  pl: documentsTranslationsPl,
  en: documentsTranslationsEn,
}

export default function (token, lang, api) {
  return new window.RWL.RTLSearch({
    token,
    language: lang,
    api,
    documentIndex: 'rtl_documents_test',
    documentsTranslations: documentsTranslations[lang] ?? documentsTranslationsEn
  });
}
