<template>
    <div id="rtl-document-section" class="document-section" v-if="documentsList.length">
        <form :action="`${$RTLSearch.settings.api}/download-document`" target="_blank" method="POST" style="display: none">
            <input id="documentFormSlug" type="hidden" name="slug">
            <input id="documentFormSendAction" type="submit" name="send">
        </form>
        <div class="container">
            <div class="row">
                <div class="col-md-8 offset-md-2">
                    <p class="document-list-title">{{$t('document_section_title')}}</p>
                </div>
            </div>
            <div class="row documents-list" v-for="document in documentsList">
                <div class="col-md-1 offset-md-2 col-1">
                    <rwl-checkbox @click="setStatus($event, document)" class="chb"><span></span></rwl-checkbox>
                </div>
                <div class="col-md-4 col-10">
                    <div class="document-title">{{ $t(getDocumentType(document))}}</div>
                    <div class="document-identifier" v-if="document.identifier">{{document.identifier}}</div>
                </div>
                <div class="col-md-4">
                    <div class="row">
                        <div class="col-md-3 col-3 offset-2 offset-md-0" style="text-align: right;">
                            <select class="select-lang" v-model="selectedAsset[`${document.identifier}_${document.type}`]">
                                <option v-for="asset in document.assets" :value="asset.languageCode">
                                    {{asset.languageCode}}
                                    <img class="download-mark" :src="icons.langMark"/>
                                </option>
                            </select>
                        </div>
                        <div class="col-md-9 col-5" style="text-align: left;">
                            <img class="download-mark" :src="icons.downloadMark"/>
                            <a class="download-doc" @click="download($event,document)">
                                {{$t('document_section_btn_download')}}
                            </a>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="row button-section">
            <div class="download-button col-md-8 offset-md-2" @click="getZipFileForDocuments()">
                <RwlButton  primary v-if="documentsList.length" :disabled="downloadButtonStatus()" variation="primary">{{$t('document_section_btn_download_all')}}
                </RwlButton>
            </div>
            <div v-if="false" class="email-button col-md-3  offset-md-1" @click="sendEmailWithDocuments()">
                <RwlButton v-if="documentsList.length" :state="downloadButtonStatus()" variation="secondary">{{$t('document_section_btn_send_email')}}
                </RwlButton>
            </div>
        </div>
    </div>
</template>

<script>
    import RwlCheckbox from "@rawlplug/rawlplug-design-library/src/components/RwlCheckbox/RwlCheckbox";
    import RwlButton from "../RwlButton";
    import RwlSelect from "@rawlplug/rawlplug-design-library/src/components/RwlSelect/RwlSelect";
    import langMark from "ThemeTemplates/assets/lang-mark.svg"
    import downloadMark from "ThemeTemplates/assets/download-mark.svg"

    export default {
        name: "DocumentSection",
        components: {RwlCheckbox, RwlButton, RwlSelect},
        props: {
            documentsList: {
                default: null,
                type: Array,
            },
            product: {
                default: null,
                type: Object
            },
            rtlSearch: {
                default: null,
                type: Object
            },
        },
        watch: {
            documentsList: function () {
                for (let document of this.$props.documentsList) {
                    const js = `{ "${document.identifier}_${document.type}": "${document.assets[0].languageCode}" }`;
                    this.selectedAsset = Object.assign(this.selectedAsset, JSON.parse(js))
                }
            }
        },
        data() {
            return {
                selectedDocuments: [],
                selectedAsset: {},
                downloadError: false,
                icons: {
                    langMark: langMark,
                    downloadMark: downloadMark,
                }
            }
        },
        methods: {
            getDocumentType(document) {
                return this.$RTLSearch.getDocumentTypeName(document.type);
            },
            download(event, documentRecord) {
                var docDefault = documentRecord.assets[0];
                const selectLang = this.selectedAsset[`${documentRecord.identifier}_${documentRecord.type}`]
                var doc = documentRecord.assets.filter(
                    (asset) => {
                        return asset.languageCode === selectLang
                    }
                );
                doc = doc.length !== 0 ? doc[0] : docDefault;
                document.getElementById('documentFormSlug').value = doc.cdn_path;
                document.getElementById('documentFormSendAction').click();
                event.stopPropagation();

                return false;
            },
            getDocumentLink(document) {
                var docDefault = document.assets[0];
                const selectLang = this.selectedAsset[`${document.uid}_${document.type}`]
                var doc = document.assets.filter(
                    (asset) => {
                        return asset.languageCode === selectLang
                    }
                );
                doc = doc.length !== 0 ? doc[0] : docDefault;

                return `${this.$RTLSearch.settings.api}/download${doc.cdn_path}`;
            },
            setStatus(event, document) {
                if (event.target.checked) {
                    this.selectedDocuments.push({
                        id: `${document.uid}_${document.type}`,
                        document: document
                    });
                } else {
                    this.selectedDocuments = this.selectedDocuments.filter(
                        (item) => item.id !== `${document.uid}_${document.type}`
                    )
                }
            },
            sendSelectedDocuments(format) {
                const dataToDownload = this.selectedDocuments.map(
                    (documentData) => {
                        return {
                            id: documentData.id,
                            uid: documentData.document.uid,
                            href: this.getDocumentLink(documentData.document),
                        }
                    }
                );
                const downloadUrl = `${this.$RTLSearch.settings.api}/download-documents`;
                const options = {
                    headers: {'Content-Type': 'application/json'},
                    method: 'POST',
                    body: JSON.stringify({
                        format: format,
                        data: {
                            email: '',
                            productUid: this.product.id,
                            productName: this.product.name,
                            documents: dataToDownload
                        }
                    })
                };
                this.$RTLSearch.sendRequest(downloadUrl, options).then((result) => {
                    this.downloadError=result.statusCode !== 201;
                    // console.log(result.status)
                    if (result.statusCode === 201) {
                        window.location.href = result.body.link;
                    }
                });
            },
            downloadButtonStatus() {
                return this.selectedDocuments.length === 0;
            },
            getZipFileForDocuments() {
                this.sendSelectedDocuments('zip');
            },
            sendEmailWithDocuments() {
                this.sendSelectedDocuments('email');
            }
        }

    }

</script>

<style scoped lang="scss">
    @import "~@rawlplug/rawlplug-design-library/src/scss/tokens/tokens";
    @import "~@rawlplug/rawlplug-style-dictionary/dist/scss/colors";
    @import "~@rawlplug/rawlplug-style-dictionary/dist/scss/spacing";
    @import "~@rawlplug/rawlplug-style-dictionary/dist/scss/typography";

    #rtl-document-section {

        background-color: $color-gray-5;
        padding: 60px 0 0;

        .download-mark {
            position: relative;
            width: 18px;
            height: 18px;
            right: 5px;
            margin-bottom: -5px;
        }

        .document-list-title, .document-title {
            text-align: left;
            margin: 0;
            font-size: 20px;
        }

        .document-list-title {
            font-size: 28px;
            margin-bottom: 40px;
        }

        .container {
            margin-bottom: 40px;
        }

        .document-identifier {
            font-weight: lighter;
            text-align: left;
            height: 20px;
        }

        input {
            float: left;
            color: rgb(0, 186, 255);
        }

        .button-section {
            padding: 45px 0;
            margin: 0;
            background-color: rgb(255, 255, 255);
        }
        select {
            background: none;
            border: 0 solid;
            width: auto;
            color: rgb(255, 138, 0);
            font-size: 18px;
            font-weight: 300;
            outline: none;
        }


        .error {
            margin-top: 20px;
        }
    }
</style>
<style>

    .chb p {
        position: relative;
        margin: 0px;
        float: right;
    }
    @media (max-width: 600px) {
        .chb p{
            float: none;
        }
    }

    .download-doc{
        font-size: 18px;
        cursor: pointer;
    }
    .documents-list {
        margin-bottom: 15px;
    }
    .documents-list > div {
        padding-top: 5px;
    }
</style>
