<template>

    <div id="rtl-app">
        <Decorator @onKeypressAction="setTextQuery">
            <SearchSection @products="selectProduct" :products="products" :searchAction="searchAction"/>
        </Decorator>
        <ProductSection v-if="!productError" @documents="selectDocuments" :product="selectedProduct"
                            :onLoadProduct="options.onLoadProductDetails"/>
        <DocumentSection v-if="!productError" :documentsList="documents" :product="selectedProduct"/>
        <div v-if="this.productError">
            <rwl-alert variation="primary" class="error-document">{{$t('app_error_no_document')}}</rwl-alert>
        </div>
    </div>

</template>

<script>
  import DocumentSection from "./components/DocumentSection"
  import ProductSection from "./components/ProductSection"
  import SearchSection from "./components/SearchSection"
  import Decorator from "./components/Decorator"
  import debounce from "./debounce"
  import 'bootstrap/scss/bootstrap-grid.scss'
  import RwlAlert from "@rawlplug/rawlplug-design-library/src/components/RwlAlert/RwlAlert"

  export default {
    name: 'app',
    components: {
      SearchSection,
      ProductSection,
      DocumentSection,
      Decorator,
      RwlAlert
    },
    mounted() {
      this.getProductsList = debounce(
        (e) => {
          if (e.target.value) {
            this.$RTLSearch.getProducts(e.target.value).then(
              (products) => {
                this.products = products
              }
            )
          }
        },
        500
      )
    },
    methods: {
      selectProduct(product) {
        this.selectedProduct = product.value;
        this.productError = false;
      },
      selectDocuments(status) {
        if (this.selectedProduct?.id) {
          this.$RTLSearch.getDocuments(this.selectedProduct.id).then(
            (documents) => {
              documents =documents.map(document => {
                document.assets = document.assets.map(asset => {
                  asset.languageCode = asset.languageCode.toUpperCase().split('_')[0];

                  return asset;
                });

                return document;
              })
              this.documents = documents;
              this.productError = !documents.length;
            }
          );
        } else {
          this.selectedProduct = {};
        }
      },
      searchAction(text) {
        // console.log('text', text)
      },
      setTextQuery(e) {
        this.getProductsList(e);
      },
      setOptions(options) {
        Object.assign(this.options, options)
      },
      setLanguage(language) {
        this.$RTLSearch.setOptions({language: language});
        this.options.language = language;
      }
    },
    data() {
      return {
        products: [],
        documents: [],
        selectedProduct: null,
        productError: false,
        options: {
          language: 'en',
          onLoadProductDetails: () => {
          }
        }
      }
    },
    documentLabelTypeName(typeLabel) {
        return typeLabel;
    }
  }

</script>

<style lang="scss">
    @import "~@rawlplug/rawlplug-design-library/src/scss/tokens/tokens";
    @import "~@rawlplug/rawlplug-design-library/src/scss/styles";

    #rtl-app {
        font-family: $font-text;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        min-height: 100vh;
        background-color: $color-gray-5;
        width: 100%;


        input[type=search] {
            height: auto;
            width: 0;
            padding: 0 7px;
            border: none;
        }

        ul {
            list-style-type: none;

            li {
                list-style-type: none;

                &:before {
                    content: none;
                }
            }
        }
    }

    body {
        margin: 0;
    }
    .error-document{
        font-size: 18px;
        margin: auto;
        width: 45%;
    }
</style>
