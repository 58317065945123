import VueI18n from 'vue-i18n';
import RTLSearch from './initRtlSearch';
import { detectLanguage } from './detectLanguage';

const languages = require('./database/languages/languages').default;

const language = detectLanguage();
let VuePlugins = {};

function setVueParameters(vue) {
  vue.prototype.$RTLSearch = RTLSearch('ALL', 'en', process.env.VUE_APP_DOWNLOAD_URL);
  vue.config.productionTip = false;
  vue.use(VueI18n);
  VuePlugins = {
    i18n: new VueI18n({
      locale: language,
      messages: languages,
    }),
  };
}

export {
  setVueParameters,
  VuePlugins,
};
