<template>
    <button class="rwl-button" :class="classes" v-bind="$attrs" v-on="$listeners">
        <slot></slot>
    </button>
</template>

<script>
    export default {
        name: 'RwlButton',
        props: {
            primary: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            classes() {
                const { primary } = this;
                return {
                    primary
                };
            },
        },
    };
</script>

<style scoped lang="scss">
    @import "~@rawlplug/rawlplug-style-dictionary/dist/scss/all";
    // BUTTON
$button-font-size: 18px;
    $button-font-weight: 500;
    $button-font-family: "Ubuntu",sans-serif;
    $button-line-height: 20px;
    $button-border-radius: $radius-rwl-3;
    $button-box-shadow: none;
    $button-inset-space: 14px 24px;
    $button-primary-color: $color-rwl-white;
    $button-primary-background-color: $color-rwl-orange-100;
    $button-primary-box-shadow: none;
    $button-primary-hover-color: white;
    $button-primary-hover-background-color: $color-rwl-orange-70;
    $button-primary-hover-box-shadow: none;
    $button-primary-focus-color: white;
    $button-primary-focus-background-color: $color-rwl-vermillion-100;
    $button-primary-focus-box-shadow: none;
    .rwl-button {
        will-change: transform;
        transition: all 0.2s ease;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-weight: $button-font-weight;
        font-size: $button-font-size;
        font-family: $button-font-family;
        line-height: $button-line-height;
        text-decoration: none;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        outline: none;
        border: 0;
        border-radius: $button-border-radius;
        background: transparent;
        cursor: pointer;
        padding: $button-inset-space;
        box-shadow: $button-box-shadow;
    &.primary {
         color: $button-primary-color;
         background-color: $button-primary-background-color;
         box-shadow: $button-primary-box-shadow;
    &:hover {
         color: $button-primary-hover-color;
         background-color: $button-primary-hover-background-color;
         box-shadow: $button-primary-hover-box-shadow;
     }
    &:active {
         color: $button-primary-focus-color;
         background-color: $button-primary-focus-background-color;
         box-shadow: $button-primary-focus-box-shadow;
     }
        &[disabled] {
            opacity: 0.2;
            color: $button-primary-color;
            background-color: $button-primary-background-color;
            cursor: initial;
        }
    }
    }
</style>
