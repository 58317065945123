import Vue from 'vue';
import App from './App.vue';
import RTLSearch from './initRtlSearch';
import { setVueParameters, VuePlugins } from './initData';

const initName = document.querySelector('#rtl-app').dataset.init;
let init = () => {
};

setVueParameters(Vue);

const vueConfig = {
  render: (h) => h(App),
  mounted() {
    init = (options) => {
      const { initOptions } = options;
      Vue.prototype.$RTLSearch = RTLSearch(initOptions.rtl.token, initOptions.rtl.lang, initOptions.rtl.api ?? process.env.VUE_APP_DOWNLOAD_URL);
      this.$children[0].setOptions(options.appOptions);
    };
  },
};

Object.assign(vueConfig, VuePlugins);
new Vue(vueConfig).$mount('#rtl-app');
init(window[initName]());
