<template>
    <div id="rtl-product-section" v-if="selectProductData" class="product-section">
        <div class="container">
            <div class="row">
                <div class="col-md-4 offset-md-2 col-lg-4">
                    <picture>
                        <img width="100%"
                             v-bind:src="getImageUrl(selectProductData)"
                             alt="">
                    </picture>
                </div>
                <div class="col-md-4 product-section--description">
                    <RwlHeading class="heading" level="h4">{{ product.name}}</RwlHeading>
                    <a class="marketing-features">{{product.marketing_features[0]}}</a>
                </div>
            </div>
<!--            <div class="row" v-if="link.length">-->
<!--                <div class="col-12 col-md-8 offset-md-2 link-cs">-->
<!--                    <a class="link" :href="link"> IDZ NA STRONE PRODUKTU <i class="arrow right"></i> </a>-->
<!--                </div>-->
<!--            </div>-->
        </div>
    </div>
</template>

<script>
  import RwlHeading from "@rawlplug/rawlplug-design-library/src/components/RwlHeading/RwlHeading"

  export default {
    name: "ProductSection",
    components: {
      RwlHeading
    },
    props: {
      documents: {},
      product: {
        default: null,
        type: Object
      },
      productDocument: {
        type: []
      },
      onLoadProduct: {
        type: Function
      }

    },
    data() {
      return {
        selectProductData: null,
        link: ''
      }
    },
    methods: {
      getImageUrl(selectProductData) {
        if (selectProductData.images && selectProductData.images.original) {
          const img = selectProductData.images.original.filter((i) => i.image_path.match(/(png|webp|jpg|jpeg)$/))[0];
          if (!img) {
              return '';
          }

          return `${selectProductData.assets_domains.rawlplug}${img.image_path}`;
        }

        return '';
      }
    },
    watch: {
      product: {
        immediate: true,
        handler: function (value) {
          if (!value?.id) {
              return;
          }
          let id = value.id;
          let data = {
            _source: ["assets_domains", "images.original"],
            query: {
              terms: {
                _id: [`${id}_${detectLanguage()}`]
              }
            }
          }
          const apiUrl = process.env.VUE_APP_DOWNLOAD_URL;
          const apiOpcIndex = process.env.VUE_APP_ES_OPC_INDEX;
          let url = `${apiUrl}/api/elastic-search-pure-proxy/${apiOpcIndex}/_search`;
          let options = {
            headers: {'Content-Type': 'application/json'},
            method: 'POST',
            body: JSON.stringify(data)
          };
          this.$RTLSearch.fetch()(url, options).then((response) => {
            response.json().then((productData) => {
              // console.log('productData', productData);
              let status = false;
              if (productData.hits.total) {
                productData = productData.hits.hits[0]._source;
                this.selectProductData = productData;
                this.onLoadProduct(value.id, (link) => {
                  this.link = link;
                });
                status = true;
              }
              this.$emit("documents", status);
            }).catch(() => {
                this.selectProductData = {
                    "uid": 0,
                    "identifier": "",
                    "name": "",
                    "short_name": "",
                    "meta_title": "",
                    "meta_description": "",
                    "path": "",
                    "order": 0,
                    "pim_category_uid": 0,
                    "locale": "",
                    "brand": [],
                    "category": [],
                };
                this.onLoadProduct(value.id, (link) => {
                    this.link = link;
                });
                this.$emit("documents", true);
            });
          })
        }
      }
    }
  }
</script>

<style scoped lang="scss">
    #rtl-product-section {
        background-color: rgb(255, 255, 255);
        padding: 60px 0;

        .link {
            color: rgb(255, 138, 0);
            text-decoration: none;
            font-size: 18px;
        }
    }

    .link-cs{
        padding-top: 50px;
    }

    .marketing-features {
        color: rgb(0, 0, 0);
        font-size: 18px;
        font-weight: lighter;
    }

    .heading {
        font-size: 28px;
    }

    .product-section {
        &--description {
            text-align: left;
        }
    }
    i {
        border: solid;
        border-color: rgb(255, 138, 0);
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 3px;
    }
    .right {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
    }
</style>
