/**
 *
 * @param settings
 * @constructor
 */
function RTLSearch(settings) {
  this.settings = {
    api: 'https://rtl.rawlplug.com',
    documentIndex: 'rtl_documents',
    token: '',
    rawData: false,
    language: 'en',
    documentsTranslations: {
      DT_MSDS: 'Karta Charakterystyki (MSDS)', //
      DT_KDWU: 'Krajowa Deklaracja Zgodności',
      DT_HYGIENIC_CERTIFICATE: 'Atest Higieniczny', //
      AD_PRODUCT_CARDS: 'Karta Techniczna Produktu (TDS)', //
      DT_APPROVAL: 'Aprobata Techniczna',
      DT_APPROVAL_ETA: 'Europejska Ocena Techniczna (ETA)', //
      DT_APPROVAL_AT: 'Aprobata Techniczna (AT)', //
      DT_FPC: 'FPC',
      DT_FPC_ETA: 'Certyfikat Europejskiej Oceny Technicznej', //
      DT_FPC_AT: 'Certyfikat Aprobaty Technicznej', //
      AD_PRODUCT_DOP: 'Deklaracja Właściwości Użytkowych (DoP)', //
      DT_DOC_SPM: 'Raporty z testów odporności ogniowej', // from RWLCOM site RTL
    }
  };

  this.setOptions(settings);
}

RTLSearch.prototype.setOptions = function (settings) {
  this.settings = Object.assign({}, this.settings, settings);
};

/**
 * @param {string} phrase
 * @return {Promise<array>} products
 */
RTLSearch.prototype.getProducts = function (phrase) {
  // eslint-disable-next-line no-undef
  var url = this.settings.api + '/api/search-products/' + this.settings.token;
  // eslint-disable-next-line no-undef
  var options = {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    method: 'POST',
    // eslint-disable-next-line no-undef
    body: 'query=' + phrase,
  };
  var results = this.sendRequest(url, options);

  return this.preparePromise(results, (products, t, s) => (this.settings.rawData ? t(products) : t(this.prepareProducts(products))));
};

RTLSearch.prototype.sendRequest = function (url, options) {
  // eslint-disable-next-line no-undef
  return fetch(url, options)
    .then(function (response) {
      return response.json();
    })
}

RTLSearch.prototype.getDocumentTypeName = function (type) {
  var data = this.settings.documentsTranslations;
  // kdz: Krajowa Deklaracja Zgodności
  // msds: Karta Charakterystyki (MSDS)
  // document: Dokument

  return data[type] ? data[type] : 'unknown (' + type + ')';
};

RTLSearch.prototype._getMarketingFeatures = function (translations) {
  var marketingFeatures = [];
  if (translations.marketing_features) {
    marketingFeatures = this._filterTranslationsByLanguage(translations.marketing_features, this.settings.language);
  }

  return marketingFeatures;
}

RTLSearch.prototype._getFeaturesAndBenefits = function (translations) {
  var featuresAndBenefits = [];
  if (translations.features_and_benefits) {
    featuresAndBenefits = this._filterTranslationsByLanguage(translations.features_and_benefits, this.settings.language);
  }

  return featuresAndBenefits;
}

RTLSearch.prototype._filterTranslationsByLanguage = function (data, language) {
  return data.filter(
    (record) => record.lang === language
  ).map(
    (product) => product.data
  );
}

RTLSearch.prototype.prepareProducts = function (products) {
  return products.hits.hits.map((document) => {
    var names = document._source.translations.name?.filter(
      (record) => (record.lang === this.settings.language)
    ) ?? '';
    var name = names.length ? names[0].data : document._source.identifier;

    return {
      id: document._id,
      identifier: document._source.identifier,
      indexes: document._source.indexes,
      name: name,
      marketing_features: this._getMarketingFeatures(document._source.translations),
      features_and_benefits: this._getFeaturesAndBenefits(document._source.translations),
    }
  });
}

RTLSearch.prototype.prepareDocuments = function (documents) {
  return documents.hits.hits[0]?._source.documents ?? [];
};

/**
 *
 * @param {number} UID
 * @return {Promise<array>} documents
 */
RTLSearch.prototype.getDocuments = function (UID) {
  var url = this.settings.api + '/api/elastic-search-pure-proxy/' + this.settings.documentIndex + '/_search';
  var data = {
    query: {
      terms: {
        _id: [UID]
      }
    }
  }
  var options = {
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
    body: JSON.stringify(data),
  };
  var results = this.sendRequest(url, options);

  return this.preparePromise(results, (documents, t, s) => {
    this.settings.rawData ? t(documents) : t(this.prepareDocuments(documents));
  });
};

RTLSearch.prototype.preparePromise = function (results, fn) {
  return new Promise((t, s) => {
    results.then((result) => fn(result, t, s));
  });
};


/**
 * Polyfill for Fetch method
 *
 * https://github.com/developit/unfetch
 */
RTLSearch.prototype.fetch = function () {
  return self.fetch || (self.fetch = function (e, n) {
    return n = n || {}, new Promise(function (t, s) {
      var r = new XMLHttpRequest, o = [], u = [], i = {}, a = function () {
        return {
          ok: 2 === (r.status / 100 | 0),
          statusText: r.statusText,
          status: r.status,
          url: r.responseURL,
          text: function () {
            return Promise.resolve(r.responseText)
          },
          json: function () {
            return Promise.resolve(JSON.parse(r.responseText))
          },
          blob: function () {
            return Promise.resolve(new Blob([r.response]))
          },
          clone: a,
          headers: {
            keys: function () {
              return o;
            }, entries: function () {
              return u;
            }, get: function (e) {
              return i[e.toLowerCase()];
            }, has: function (e) {
              return e.toLowerCase() in i;
            },
          },
        }
      };
      for (var c in r.open(n.method || 'get', e, !0), r.onload = function () {
        r.getAllResponseHeaders().replace(/^(.*?):[^\S\n]*([\s\S]*?)$/gm, function (e, n, t) {
          o.push(n = n.toLowerCase()), u.push([n, t]), i[n] = i[n] ? i[n] + ',' + t : t
        }), t(a());
      }, r.onerror = s, r.withCredentials = (n.credentials === 'include'), n.headers) r.setRequestHeader(c, n.headers[c]);
      r.send(n.body || null);
    });
  });
};

window.RWL = window.RWL || {};
window.RWL.RTLSearch = RTLSearch;
