<script>
    export default {
        render() {
            return this.$slots.default[0];
        },
        mounted() {
            this.$el.querySelector('input').addEventListener("keyup", this.keypressAction)
        },
        methods: {
            keypressAction(e) {
                this.$emit('onKeypressAction', e);
            }
        }
    }
</script>
