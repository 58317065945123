export function detectLanguage() {
  const availableLanguages = String(process.env.VUE_APP_AVAILABLE_LANGUAGE).split(',');

  return [
    ...(window.navigator.languages || []),
    window.navigator.language,
    window.navigator.browserLanguage,
    window.navigator.userLanguage,
    window.navigator.systemLanguage,
  ]
    .filter(Boolean)
    .map((language) => language.toLowerCase())
    .find((language) => availableLanguages.includes(language)) || 'en';
}

window.detectLanguage = detectLanguage;
