<template>
    <div id="rtl-search-section" class="Title">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-8 offset-md-2">
                    <RwlHeading class="heading" level="h2">{{ $t('search_section_title') }}</RwlHeading>
                    <RwlHeading class="intro-text" level="h6">{{ $t('search_section_description') }}</RwlHeading>
                    <RwlSelect class="select-product select2_no" @search="updateSearchValue" :filterable="false"
                               :clearSearchOnSelect="false"
                               single label="name" @input="setSelected" :value="selectedProduct"
                               :options="getFilterProducts">
                        <template v-slot:no-options>
                            <div v-if="inputText===''" @mousedown.stop="">{{ $t('search_section_start_typing') }}</div>
                            <div v-else @mousedown.stop="">{{ $t('search_section_no_results') }}</div>
                        </template>
                        <template v-slot:option="option">
                            <img :class="option.type" width="16"
                                 :src="icons[option.type]"
                                 alt="">{{ option.name }}
                        </template>
                    </RwlSelect>
                </div>
            </div>
        </div>
    </div>


</template>

<script>

import subgroupIndexLine from "ThemeTemplates/assets/subgroup-index-line.svg"
import indexSuggester from "ThemeTemplates/assets/index-suggester.svg"
import RwlHeading from "@rawlplug/rawlplug-design-library/src/components/RwlHeading/RwlHeading"
import RwlSelect from "@rawlplug/rawlplug-design-library/src/components/RwlSelect/RwlSelect"

export default {
    name: "SearchSection",
    props: ["products", "searchAction"],
    components: {
        RwlHeading,
        RwlSelect
    },
    data() {
        return {
            selectedProduct: null,
            searchTextQuery: '',
            icons: {
                product: subgroupIndexLine,
                index: indexSuggester,
            },
            inputText: ""
        }
    },
    methods: {
        setSelected(value) {
            this.selectedProduct = value
            this.$emit("products", value)

        },
        updateSearchValue(val) {
            this.inputText = val;
        }

    },
    computed: {
        getFilterProducts() {
            let ar = [];
            for (let i of getFilterProducts2(this.products)) {
                ar.push(i);
            }

            return ar;
        },
    }

}

function* getFilterProducts2(products) {
    for (let product of products) {
        yield {
            name: product.name,
            value: product,
            type: "product"
        };
        if (product.indexes && product.indexes.length) {
            for (let i in product.indexes) {
                yield {
                    name: product.indexes[i],
                    value: product,
                    type: "index"
                }
            }
        }
    }
}

</script>


<style scoped lang="scss">
    @import "~@rawlplug/rawlplug-design-library/src/scss/tokens/tokens";

    #rtl-search-section {
        background-color: $color-gray-5;
        padding: 60px 0;

        .intro-text {
            text-align: left;
            font-size: 18px;
        }
    }
</style>

<style lang="scss">
    @import "~@rawlplug/rawlplug-design-library/src/scss/tokens/tokens";
    @import "~@rawlplug/rawlplug-style-dictionary/dist/scss/colors";

    #rtl-search-section {
        .rwl-heading.heading.h2 {
            margin: 0;
            margin-bottom: 25px;
            color: $color-rwl-blue-100;
            font-size: 28px;
        }

        .input-label {
            text-align: left;

            input {
                background-image: url("~ThemeTemplates/assets/SearchIcon.svg") !important;
                background-repeat: no-repeat !important;
                background-position: right center !important;
            }

        }

        .select-product {
            position: relative;

            &::after {
                content: "";
                position: absolute;
                width: 20px;
                height: 20px;
                bottom: 10px;
                right: 10px;
                background-image: url('~ThemeTemplates/assets/SearchIcon.svg');
                background-size: contain;
                background-color: $color-white;
            }

            & > div div:last-child {
                display: none;
            }

            .vs__dropdown-toggle {
                background: $color-white;
            }

            img {
                &.index,
                &.product {
                    object-fit: cover;
                    width: 20px;
                    height: 19px;
                    object-position: top;
                    vertical-align: middle;
                    margin-right: 5px;
                }

                &.index {
                    width: 21px;
                    height: 21px;
                    margin-left: 25px;
                }
            }
        }
    }
</style>

<style lang="scss">
    $borderColor: #9EBAF2;

    * {
        font-family: Ubuntu;
    }

    #rtl-app {
        background-color: #fff !important;
        width: 100% !important;
        margin: auto !important;
    }

    #rtl-search-section {
        background: linear-gradient(285.99deg, #EDF3FE 13.61%, #F2F7FF 100%);

        .rwl-heading.heading.h2 {
            color: #003366;
            font-family: Ubuntu;
            font-size: 35px;
            font-weight: 700;
        }

        .select-product {
            &::after {
                display: none;
            }
        }
    }

    #rtl-product-section {
        margin-top: 30px;
        border: 1px solid $borderColor;
        border-radius: 8px 8px 0 0;
        border-bottom: 0;
        padding-bottom: 30px !important;

        .container::after {
            content: "";
            border-bottom: 1px solid #9EBAF2;
            width: 100%;
            display: block;
            margin-top: 20px;
        }
    }

    #rtl-document-section {
        background-color: #fff !important;
        border: 1px solid $borderColor;
        border-radius: 0 0 8px 8px;
        border-top: 0;
        padding-top: 0 !important;

        .container {
            margin-bottom: 30px !important;

            &::after {
                content: "";
                border-bottom: 1px solid #F1F1F1;
                width: 100%;
                display: block;
                margin-top: 20px;
            }
        }
    }

    #rtl-product-section,
    #rtl-document-section {
        .container .row {
            .offset-md-2 {
                margin-left: 0;
            }
        }
    }

    .download-doc {
        &, &:hover {
            color: #036;
            text-decoration: underline;
            display: inline-block;
        }
    }

    .select-lang {
        color: #036 !important;
        border: 1px solid #9EBAF2 !important;
        border-radius: 40px;
        font-size: 14px !important;
        font-weight: 500 !important;
        padding: 3px 4px;
        width: 88px !important;
        max-width: 88px;
    }

    .download-mark {
        margin: auto 5px auto 20px;
    }

    .documents-list {
        > div:first-child {
            width: auto;
            max-width: 45px;
            padding-right: 0;
        }
    }

    .button-section {
        background-color: transparent !important;
        padding-top: 0 !important;
        padding-bottom: 30px !important;

        > div {
            width: 100% !important;
            display: flex;
            flex-direction: row-reverse;
            max-width: 100%;
            flex: 100%;
            margin: 0 29px;
            padding: 0 15px;
        }
    }

    .checkbox__select-icon {
        background: #003366;
        border-radius: 4px;
        height: 30px !important;
        width: 30px !important;
    }
    .checkbox__select {
        &, &::before {
            height: 30px !important;
            width: 30px !important;
        }
    }

    .checkbox__text {
         margin-top: 8px !important;
    }

    .vs__dropdown-toggle {
        border-radius: 25px !important;
        border: 2px solid #9EBAF2 !important;
        padding-left: 15px !important;
    }

    .vs__dropdown-menu {
        margin-top: 5px !important;
        border-radius: 10px !important;
    }

    .vs__dropdown-option {
        font-size: 14px;
        padding-top: 8px !important;
        padding-bottom: 8px !important;
        img {
            width: 16px !important;;
            height: 16px !important;
        }

        &:hover {
            background-color: #E2ECFE;
            color: #036;
            font-weight: 700;
            text-decoration: underline;

            &::after {
                content: ">";
                position: absolute;
                right: 10px;
            }
        }
    }
</style>